import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgFor} from '@angular/common';

@Component({
	selector: 'app-brand-switcher',
	templateUrl: './brand-switcher.component.html',
	styleUrls: ['./brand-switcher.component.scss'],
	standalone: true,
	imports: [NgFor]
})
export class BrandSwitcherComponent {
	private _router = inject(Router);
	private _activatedRoute = inject(ActivatedRoute);
	
	brands = [
		{name: 'bugatti', icon: 'bugatti_white.svg'},
		{name: 'bagatt', icon: 'bagatt_icon.svg'},
		{name: 'elwin', icon: 'elwin_icon.svg'},
		{name: 'daniel_hechter', icon: 'daniel_hechter_icon_blue.svg'},
		{name: 'salamander', icon: 'salamander_favicon.svg'}
	];
	
	/**
	 * Method to change brand in development/testing
	 */
	onBrandSwitch(brand: string): void {
		const lang= this._activatedRoute.snapshot.queryParams.lang || 'en';
		this._router.navigateByUrl(`check-order/${brand}?lang=${lang}`).then(() => {
			window.location.reload();
		})
	}
	
}
